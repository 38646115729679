import React, { useState, useEffect, useRef, useContext } from "react";
import { ResponsiveContainer, Cell, PieChart, Pie } from "recharts";
import Title from "./Title";
import useTickets from "../../hooks/useTickets";
import { AuthContext } from "../../context/Auth/AuthContext";
import Row from "../../layout/components/Row";
import Column from "../../layout/components/Column";
import { CircularProgress, Grid, TextField, useTheme } from "@material-ui/core";
import CalendarToday from "@material-ui/icons/CalendarTodayRounded";
import Modal from "../../layout/components/Modal";

const ChartRating = () => {
  const { user } = useContext(AuthContext);
  const theme = useTheme();

  const getStartOfMonth = () => {
    const now = new Date();
    const iso = new Date(now.getFullYear(), now.getMonth(), 1).toISOString();
    return iso;
  };
  const getEndOfMonth = () => {
    const now = new Date();
    const iso = new Date(
      now.getFullYear(),
      now.getMonth() + 1,
      0
    ).toISOString();
    return iso;
  };

  const [modalFilterOpen, setModalFilterOpen] = useState(false);
  const [initialDate, setInitialDate] = useState(getStartOfMonth());
  const [finalDate, setFinalDate] = useState(getEndOfMonth());

  const initialDateInput = useRef();
  const finalDateInput = useRef();

  var userQueueIds = [];

  if (user.queues && user.queues.length > 0) {
    userQueueIds = user.queues.map(q => q.id);
  }

  const { tickets, loading } = useTickets({
    status: "closed",
    showAll: "true",
    withUnreadMessages: "false",
    queueIds: JSON.stringify(userQueueIds),
    date: initialDate,
    fDate: finalDate,
    unlimited: "true"
  });

  const [chartData, setChartData] = useState([
    { name: "Péssimo", value: 1 },
    { name: "Ruim", value: 2 },
    { name: "Bom", value: 15 },
    { name: "Otimo", value: 4 },
    { name: "Excelente", value: 2 }
  ]);

  useEffect(() => {
    setChartData(prevState => {
      let aux = [...prevState];

      aux[0].value = tickets.filter(t => t.rate === 1).length;
      aux[1].value = tickets.filter(t => t.rate === 2).length;
      aux[2].value = tickets.filter(t => t.rate === 3).length;
      aux[3].value = tickets.filter(t => t.rate === 4).length;
      aux[4].value = tickets.filter(t => t.rate === 5).length;

      return aux;
    });
  }, [tickets]);

  const COLORS = ["#161616", "#10342d", "#0b5242", "#056f58", "#008C6E"];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.3;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {percent > 0 && `${index + 1} - ${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const handleFilterClick = e => {
    e.preventDefault();
    setModalFilterOpen(prev => !prev);
  };

  const handleInitialDateChange = value => {
    setInitialDate(new Date(value + "T04:00:00.000Z").toISOString());
  };

  const handleFinalDateChange = value => {
    setFinalDate(new Date(value + "T04:00:00.000Z").toISOString());
  };

  return (
    <React.Fragment>
      <Row>
        <Column>
          <Title noMargin>{`Avaliações`}</Title>
          <span
            style={{ fontSize: ".7rem", color: theme.palette.text.secondary }}
          >
            {new Date(initialDate).toLocaleDateString()} -{" "}
            {new Date(finalDate).toLocaleDateString()}
          </span>
        </Column>
        <Column wAuto>
          <CalendarToday
            fontSize="small"
            style={{ cursor: "pointer" }}
            onClick={handleFilterClick}
          />
          <Modal
            modalOpen={modalFilterOpen}
            onClose={() => {
              setModalFilterOpen(false);
            }}
            onOK={() => {
              handleInitialDateChange(initialDateInput.current.value);
              handleFinalDateChange(finalDateInput.current.value);
              setModalFilterOpen(false);

              //TODO: UpdateTicket
            }}
            title={"Filtros"}
          >
            <Grid container spacing={1}>
              <Grid item>
                <TextField
                  inputRef={initialDateInput}
                  label={"Data Inicial"}
                  variant="outlined"
                  type="date"
                  defaultValue={initialDate.slice(0, 10)}
                  //onChange={handleInitialDateChange}
                />
              </Grid>
              <Grid item>
                <TextField
                  inputRef={finalDateInput}
                  label={"Data Final"}
                  variant="outlined"
                  type="date"
                  defaultValue={finalDate.slice(0, 10)}
                  //onChange={handleFinalDateChange}
                />
              </Grid>
            </Grid>
          </Modal>
        </Column>
      </Row>
      {loading ? (
        <Row h100 aiCenter jcCenter>
          <center>
            <CircularProgress />
          </center>
        </Row>
      ) : tickets.length === 0 ? (
        <Row h100 aiCenter jcCenter>
          <center>
            <span style={{ color: "red" }}>
              Sem dados para exibir o gráfico
            </span>
          </center>
        </Row>
      ) : (
        <Row h100 aiCenter>
          <Column w40>
            <Row aiCenter>
              <div
                style={{
                  content: "*",
                  width: "30%",
                  backgroundColor: COLORS[0],
                  height: 10,
                  borderRadius: 20
                }}
              ></div>
              <span style={{ width: "100%", marginLeft: 7 }}>
                1 - Péssimo ({chartData[0].value})
              </span>
            </Row>
            <Row aiCenter>
              <div
                style={{
                  content: "*",
                  width: "30%",
                  backgroundColor: COLORS[1],
                  height: 10,
                  borderRadius: 20
                }}
              ></div>
              <span style={{ width: "100%", marginLeft: 7 }}>
                2 - Ruim ({chartData[1].value})
              </span>
            </Row>
            <Row aiCenter>
              <div
                style={{
                  content: "*",
                  width: "30%",
                  backgroundColor: COLORS[2],
                  height: 10,
                  borderRadius: 20
                }}
              ></div>
              <span style={{ width: "100%", marginLeft: 7 }}>
                3 - Bom ({chartData[2].value})
              </span>
            </Row>
            <Row aiCenter>
              <div
                style={{
                  content: "*",
                  width: "30%",
                  backgroundColor: COLORS[3],
                  height: 10,
                  borderRadius: 20
                }}
              ></div>
              <span style={{ width: "100%", marginLeft: 7 }}>
                4 - Otimo ({chartData[3].value})
              </span>
            </Row>
            <Row aiCenter>
              <div
                style={{
                  content: "*",
                  width: "30%",
                  backgroundColor: COLORS[4],
                  height: 10,
                  borderRadius: 20
                }}
              ></div>
              <span style={{ width: "100%", marginLeft: 7 }}>
                5 - Excelente ({chartData[4].value})
              </span>
            </Row>
          </Column>
          <div
            style={{
              width: "100%",
              height: "100%"
            }}
          >
            <ResponsiveContainer>
              <PieChart
                width={730}
                height={250}
                margin={{
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0
                }}
              >
                <Pie
                  data={chartData}
                  cx="49%"
                  cy="80%"
                  labelLine={true}
                  label={renderCustomizedLabel}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                  startAngle={180}
                  endAngle={0}
                >
                  {chartData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
        </Row>
      )}
    </React.Fragment>
  );
};

export default ChartRating;
